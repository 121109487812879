import React from 'react';
import RecipeSectionItem from './section';

import './recipe.scss';

function RecipeContent(props) {
  const sectionHeader = `/images/${process.env.REACT_APP_BRAND_NAME}/recipes/sectionHeader.png`;
  return (
    <div className="recipe-content-page" id="recipe">
      <div className='recipeArea'>
        <img className='sectionTitle' src={sectionHeader} alt={props.recipes.sectionHeader}/>
        <div className="recipe-content">
          {props.recipes.recipeSection.map((item, i) => {
            return <RecipeSectionItem section={item} key={i}></RecipeSectionItem>;
          })}
        </div>
      </div>
      <div className='clearer'></div>
    </div>
  );
}
export default RecipeContent;
