import React from 'react';
import { Link } from "react-router-dom";

function RecipeContentItem(props) {
  const { title } = props.recipe;
  const { cardImg, a2c } = props.recipe.card;
  const cardImg_path = `/images/${process.env.REACT_APP_BRAND_NAME}/${cardImg}`;
  const linkTitle = title.replace(/ /g, '-');

  function trackAddToCartEvent (){
    window.gtag('event', 'addtocartclick', { 
      product: title
     });
  }
  function trackGetRecipe (){
    window.gtag('event', 'viewrecipe', { 
      recipe: title
     });
  }

  return (
    <div className="recipe-item">
    {
        <div>            
          <img src={cardImg_path} className="card-img-top content-img" alt={title} /> 

          <div className='buttonContent'>
            <button className="item-btn cart-btn" >
              <a 
                href={a2c} 
                onClick={trackAddToCartEvent} 
                target="_blank" 
                rel="noopener noreferrer"
              >Add to Cart</a>
            </button>
            <button className="item-btn recipe-btn">
              <Link to={"/recipes/"+linkTitle}
              onClick={trackGetRecipe}
              >Get Recipe</Link>
            </button>
          </div>
        </div>
        }
    </div>
  );
}
export default RecipeContentItem;
