import React from 'react';

import './legal.scss';

function Legal() {
  const brand = process.env.REACT_APP_BRAND_NAME;
  let path = '/images/' + brand + '/legal.jpg';
  let mobileUrl = path.replace('.jpg', '_mobile.jpg');
  
  return (
    <div className="legal-content">
      <img
        className={`legal-Img`}
        key='legal'
        src={path}
        srcSet={`${mobileUrl} 1943w,${path} 2200w`}
        sizes="(max-width: 640px) 100vw, 2200px"
        alt=''
      /> 
    </div>
  );
}
export default Legal;
