import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import store from '../redux';

import { useDispatch, useSelector } from 'react-redux';
import { SHOW_LOGIN, CLIP_ERROR, CLIP_OFFER } from '../redux/reducers/offer';
import './moreinfo.scss';

const config = store.getState().config;

function OfferItem(props) {
  const dispatch = useDispatch();

  let coupon = props.coupon || '';
  let isFeatured = props.isFeatured;
  let [moreInfo, showInfo] = useState({ show: false });
  const username = useSelector((state) => state.user.username);
  const useStatic = useSelector((state) => state.config.useStatic);
  let linkCondition =
    config.urlParam.ids && config.urlParam.ids.split(',').length === 1;
  let mpidbyEnv = store.getState().config.myEnv;
  const mpid = store.getState().config.retailer.mpid[mpidbyEnv];
  let funnel = process.env.REACT_APP_FUNNEL ? process.env.REACT_APP_FUNNEL : 'n/a';
  let channel = process.env.REACT_APP_CHANNEL ? process.env.REACT_APP_CHANNEL : 'n/a';
  // let marketingEvent = process.env.REACT_APP_MARKETING_EVENT ? process.env.REACT_APP_MARKETING_EVENT : 'n/a';
  const {
    REACT_APP_COLOR_PRIMARY: primary,
    REACT_APP_COLOR_SECONDARY: secondary,
    REACT_APP_COLOR_LINKS: links,
    REACT_APP_COLOR_TITLE: title,
  } = process.env;

  function handleViewMore(e) {
    e.preventDefault();
    showInfo({ show: true });
  }

  function handleClose(e) {
    e.preventDefault();
    showInfo({ show: false });
  }
  function RenderMoreInfo(props) {
    if (!moreInfo.show) {
      return '';
    }
    return (
      <div className="moreInfo">
        <div
          className="overlay"
          style={{ backgroundColor: primary, opacity: '.99' }}
        ></div>

        <div className="content">
          <button
            className="close"
            onClick={(e) => {
              handleClose(e);
            }}
          >
            X
          </button>
          <p>{props.description}</p>
        </div>
      </div>
    );
  }
  function RenderCTA(props) {
    let element = (
      <button
        style={{ backgroundColor: primary }}
        onClick={handleClick.bind(this, mdid)}
        className="offer-btn"
      >
        Clip
      </button>
    );
    if (coupon.clipped) {
      element = (
        <button
          className="offer-btn link"
          disabled
          style={{ backgroundColor: secondary }}
        >
          Clipped
        </button>
      );
    }
    return element;
  }
  function RenderFeaturedLogo() {
    if (featuredLogo && isFeatured) {
      return <Card.Img src={featuredLogo} alt="" className="logo-img" />;
    } else {
      return null;
    }
  }
  function OfferImageCheck() {
    if (isFeatured && enhancedImageUrl) {
      return (
        <Card.Img
          src={enhancedImageUrl}
          alt={description}
          className="offer-img"
        />
      );
    } else {
      return (
        <Card.Img src={imageUrl} alt={description} className="offer-img" />
      );
    }
  }
  function handleClick(mdid) {
    const options = {
      endpoint: 'offers/load',
      method: 'POST',
      body: { 
        username: username, 
        id: mdid,
        mediaPropertyId: mpid,
        channel: channel,
        funnel: funnel,
        // marketingEvent: marketingEvent, 
        captchaToken: '22' 
      },
    };

    if (useStatic) {
      options.endpoint = 'json/clip.json';
    }
    if (!username) {
      dispatch(SHOW_LOGIN({ show: true, mdid: mdid }));
    } else {
      handleClip(options, mdid);
    }
  }

  function handleClip(options, mdid) {
    dispatch(CLIP_OFFER(options)).then((resp) => {
      //TODO: errors not firing
      if (!resp.payload.success) {
        dispatch(CLIP_ERROR(resp));
      }
    });
  }

  const { imageUrl, enhancedImageUrl, brand, description, mdid, clipEndDateTime } = coupon;

  const expiration = new Date(clipEndDateTime.iso);
  const month = expiration.getMonth() + 1;
  const day = expiration.getDate();
  let year = expiration.getFullYear().toString().substr(2, 4);
  const featuredLogo = config.urlParam.LOGO || config.urlParam.logo;

  return (
    <Card className="offer">
      <div className="featured-block">
        <a href={`?ids=${mdid}`} className={linkCondition ? 'disable-link' : ''}>
          <OfferImageCheck />
        </a>
        <div>
          <Card.Body className="content">
            <RenderFeaturedLogo />
            <Card.Title style={{ color: title }}>{brand}</Card.Title>
            <Card.Text className="description">{description}</Card.Text>
            <Card.Link
              className="moreLink"
              style={{ color: links }}
              onClick={(e) => {
                handleViewMore(e);
              }}
              href="#"
            >
              View More
            </Card.Link>
            <Card.Text className="expiration">
              Exp. {month}/{day}/{year}
            </Card.Text>
          </Card.Body>
          <RenderCTA mdid={mdid} />
          <RenderMoreInfo description={description} />
        </div>
      </div>
    </Card>
  );
}

export default OfferItem;
