import React, { useEffect, useState } from 'react';
import ContentItem from './item';
import TikTokContentItem from './tiktok';

import './content.scss';

function Content() {
  let [isReady, setReady] = useState(false);
  let [data, setContent] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        'json/' + process.env.REACT_APP_BRAND_NAME + '/content.json',
      );
      const data = await response.json();
      setContent(data);
      setReady(true);
    };
    fetchData();
  }, []);

  if (!isReady) {
    return '<div>..loading</div>';
  } else {
    return (
      <div className="content-page">
        {data.hashTag && <h2 className="hashText">{data.hashTag}</h2>}
       
        {data.tiktok && <div className="content-tiktok">
          {data.tiktok.map((item, i) => {
            return <TikTokContentItem item={item} key={i}></TikTokContentItem>;
          })}
        </div>}
        {data.posts && <div className="content">
          {data.posts.map((item, i) => {
            return <ContentItem item={item} key={i}></ContentItem>;
          })}
        </div>}
      </div>
    );
  }
}
export default Content;
