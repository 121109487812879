import React from 'react';
import LazyLoad from 'react-lazy-load';

import './content.scss';

function ContentItem(props) {
  const { title, url, image } = props.item;
  const img_path = `/images/${process.env.REACT_APP_BRAND_NAME}/${image}`;

  function mediaLink(type) {
    switch (type) {
      case 'pinterest':
        return `https://www.pinterest.com/pin/create/button/?url=${url}&media=${img_path}&description=${encodeURIComponent(
          title,
        )}`;
      case 'facebook':
        return `https://www.facebook.com/share.php?u=${url}&title=${encodeURIComponent(
          title,
        )}`;
      case 'twitter':
        return `http://twitter.com/share?text=${encodeURIComponent(
          title,
        )}&url=${url}`;
      default:
        break;
    }
  }

  return (
    <LazyLoad offsetTop={100}>
      <div className="card content-item">
        <a href={url} target="_blank" rel="noopener noreferrer">
          <img src={img_path} className="card-img-top content-img" alt="" />
        </a>
        <div className="card-body">
          <div className="card-title">{title}</div>
          <div className="card-text">
            {['pinterest', 'facebook', 'twitter'].map((media, i) => {
              return (
                <a
                  href={mediaLink(media)}
                  target="_blank"
                  className="btn-social"
                  key={i}
                  rel="noopener noreferrer"
                >
                  <i className={`fa fa-${media}`}></i>
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </LazyLoad>
  );
}
export default ContentItem;
