import React from 'react';
import {useParams} from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';

function RecipePage(props) {    
    const { recipeName } = useParams();
    const recipeTitle = recipeName.replace(/-/g,' ');
    const recipeList = [];
    const url = window.location.href;
    
    props.recipes.recipeSection.map((obj) => {
        return obj.sectionItems.map((items) =>{
          return recipeList.push(items)
        })       
    })
    const recipeItem = recipeList.find(obj=>obj.title === recipeTitle);
    const a2c = recipeItem.card.a2c;
    const path = '/images/'+process.env.REACT_APP_BRAND_NAME+'/'+recipeItem.recipeDetail.headerImg;
    const recipePDF = '/images/'+process.env.REACT_APP_BRAND_NAME+'/recipes/pdf/'+recipeName+'.pdf';
    function mediaLink(type) {
      switch (type) {
        case 'pinterest':
          return `https://www.pinterest.com/pin/create/button/?url=${url}&media=${path}&description=${encodeURIComponent(
            recipeTitle,
          )}`;
        case 'facebook':
          return `https://www.facebook.com/share.php?u=${url}&title=${encodeURIComponent(
            recipeTitle,
          )}`;
        case 'twitter':
          return `http://twitter.com/share?text=${encodeURIComponent(
            recipeTitle,
          )}&url=${url}`;
        case 'email':
          return `mailto:?subject=I think you will enjoy this recipe!&body=Here is a recipe that I know you'll love: ${encodeURIComponent(recipeTitle)} - ${url}`;
        case 'print':
          return`${recipePDF}`;
          default:
          break;
      }
    }
    function trackAddToCartEvent(){
      window.gtag('event', 'addtocartclick', { 
        product: recipeTitle
      });
    }
    function trackShare(e){
      const type = e.currentTarget.getAttribute("data-value")
      window.gtag('event', 'trackshare', { 
        mediaType: type,
        recipeTitle: recipeTitle
      });
    }

    return (
      <div className="recipeDetail">
        <div className="header">
            <img src={path} alt={recipeTitle}/>
        </div>
        <div className="subheader">
          <div className='border'>
            <img src={'/images/'+process.env.REACT_APP_BRAND_NAME+'/'+recipeItem.recipeDetail.border} alt=""/>
          </div>
          
          <div className='subheaderContent'>
            <div className='social'>
                  <h3>{recipeItem.recipeDetail.subheader}</h3>
                  <div className='socialIcons'>
                  {['facebook','pinterest','email','print'].map((social, i) => {
                    let socialImage = '/images/'+process.env.REACT_APP_BRAND_NAME+'/recipes/social/'+social+'.png';
                      return (
                        <a
                          href={mediaLink(social)}
                          onClick={trackShare}
                          data-value={social}
                          target="_blank"
                          className="btn-social"
                          key={i}
                          rel="noopener noreferrer"
                        >
                          <img src={socialImage} alt={social}/>
                        </a>
                      );
                  })}

                  </div>
            </div>
            <div className='times'>
                <div className=''>
                      <img src={'/images/'+process.env.REACT_APP_BRAND_NAME+'/recipes/'+ (recipeItem.recipeDetail.times.prep)} alt="Prep Time"/>
                  </div>
                  <div className=''>
                      <img src={'/images/'+process.env.REACT_APP_BRAND_NAME+'/recipes/'+ (recipeItem.recipeDetail.times.total)} alt="Total Time"/>
                  </div>
                  <div className=''>
                      <img src={'/images/'+process.env.REACT_APP_BRAND_NAME+'/recipes/'+ (recipeItem.recipeDetail.times.servings)} alt="Serving Size"/>
                  </div>
            </div>
          </div>
          <div className='border'>
            <img src={'/images/'+process.env.REACT_APP_BRAND_NAME+'/'+recipeItem.recipeDetail.border} alt=""/>
          </div>
        </div>
        <div className="detail">
            <div className="ingredients">
                <h3>Ingredients</h3>
                <button className="cart-btn" >
                    <a 
                        href={a2c} 
                        onClick={trackAddToCartEvent} 
                        target="_blank" 
                        rel="noopener noreferrer"
                    >Add to Cart</a>
                </button>
                {ReactHtmlParser(recipeItem.recipeDetail.ingredients)}
            </div>
            <div className="directions">
                <h3>Directions</h3>
                {ReactHtmlParser(recipeItem.recipeDetail.instructions)}
            </div>
        </div>
      </div>
    );
}
export default RecipePage;
