import React from 'react';
import LazyLoad from 'react-lazy-load';

import './content.scss';

function TikTokContentItem(props) {
  const { id } = props.item;
  const tiktokSrc = `https://www.tiktok.com/embed/${id}`;
    
  return (
    <LazyLoad offsetTop={100}>
      <div className="card content-item">
        <div className="parent" >
          <iframe src={tiktokSrc} title={id} allowfullscreen scrolling="no" allow="encrypted-media;"></iframe>
        </div>    
      </div>
    </LazyLoad>
  );
}
export default TikTokContentItem;
