import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import './content.scss';

function CartContentItem(props) {
  const { title, url, image, buttonBG, buttonColor } = props.item;
  const img_path = `/images/${process.env.REACT_APP_BRAND_NAME}/${image}`;

  function trackAddToCartEvent (){
    window.gtag('event', 'addtocartclick', { 
      product: title
     });
  }

  return (
    <div className="cart-card">
      <div className='card-item'>
        <img src={img_path} className="card-img-top content-img" alt="" />
        <div className="card-title h6">{ReactHtmlParser(title)}</div>
        <button className="cart-btn" style={{ backgroundColor: buttonBG, color: buttonColor }}>
          <a 
            href={url} 
            onClick={trackAddToCartEvent} 
            target="_blank" 
            rel="noopener noreferrer"
          >Add to Cart</a>
        </button>
      </div>
    </div>
  );
}
export default CartContentItem;
