import React from 'react';

import './hero.scss';

function Hero() {
  const text = process.env.REACT_APP_HERO_ALT;
  const brand = process.env.REACT_APP_BRAND_NAME;
  const anchor = process.env.REACT_APP_HERO_ANCHOR;
  let heroPath = '/images/' + brand + '/hero.jpg';
  let heroMobileUrl = heroPath.replace('.jpg', '_mobile.jpg');
  let gardenPath = '/images/' + brand + '/garden.jpg';
  let gardenMobileUrl = gardenPath.replace('.jpg', '_mobile.jpg');
  return (
    <div className='header'>
      <div className="hero">
        { anchor ?
          <a href={anchor}>
            <img
              className={`heroImg`}
              src={heroPath}
              srcSet={`${heroMobileUrl} 1943w,${heroPath} 2200w`}
              sizes="(max-width: 640px) 100vw, 2200px"
              alt={text}
            />
          </a>
          :  
          <img
            className={`heroImg`}
            src={heroPath}
            srcSet={`${heroMobileUrl} 1943w,${heroPath} 2200w`}
            sizes="(max-width: 640px) 100vw, 2200px"
            alt={text}
          />
        }
        
      </div>
      <div className='garden'>
        <img
          className={`gardenImg`}
          src={gardenPath}
          srcSet={`${gardenMobileUrl} 1943w,${gardenPath} 2200w`}
          sizes="(max-width: 640px) 100vw, 2200px"
          alt=""
        />
      </div>
    </div>
  );
}
export default Hero;
